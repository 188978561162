const ACTION_KEY = 'player';

/**
 * actions
 */
export const PAUSE = `${ACTION_KEY}/pause`;
export const PLAY = `${ACTION_KEY}/play`;
export const SET_CURRENT = `${ACTION_KEY}/set-current`;
export const SET_PLAYLIST = `${ACTION_KEY}/set-playlist`;
export const CLEAR_PLAYLIST = `${ACTION_KEY}/clear-playlist`;

export const TOGGLE = `${ACTION_KEY}/toggle`;

/**
 * selectors
 */
export const select = state => state.player;
export const selectCurrent = state => select(state).current;
export const selectIsCurrent = (state, sound) => {
	const current = selectCurrent(state);
	if (!current || !sound) return false;
	return current === sound._id;
};

export const selectIsPlaying = state => select(state).isPlaying;

export const selectPlaylist = state => select(state).playlist;
export const selectCurrentSound = (state) => {
	const playlist = selectPlaylist(state);
	const current = selectCurrent(state);
	const currentIndex = playlist.findIndex(sound => sound._id === current);
	if (currentIndex === -1) return false;
	return playlist[currentIndex];
};

/**
 * actions creators
 */
export const clearPlaylistAction = () => ({ type: CLEAR_PLAYLIST });
export const pauseAction = () => ({ type: PAUSE });
export const playAction = () => ({ type: PLAY });
export const setCurrentAction = sound => ({ type: SET_CURRENT, sound });
export const setPlaylistAction = sounds => ({ type: SET_PLAYLIST, playlist: sounds });
export const toggleAction = () => ({ type: TOGGLE });

export const startPlaylistAction = (sounds, sound) => ({ dispatch }) => {
	dispatch(pauseAction());
	setTimeout(() => {
		dispatch(setPlaylistAction(sounds));
		dispatch(setCurrentAction(sound));
		dispatch(playAction());
	});
};

export const stopAction = () => ({ dispatch }) => {
	dispatch(pauseAction());
	dispatch(setCurrentAction());
	dispatch(clearPlaylistAction());
};

export const nextAction = () => ({ dispatch, getState }) => {
	const state = getState();
	const playlist = selectPlaylist(state);

	const current = selectCurrent(state);
	const currentIndex = playlist.findIndex(sound => sound._id === current);

	const nextIndex = (currentIndex + 1) % playlist.length;
	const next = playlist[nextIndex];
	dispatch(setCurrentAction(next));
};

export const previousAction = () => ({ dispatch, getState }) => {
	const state = getState();
	const playlist = selectPlaylist(state);

	const { length } = playlist;
	const current = selectCurrent(state);
	const currentIndex = playlist.findIndex(sound => sound._id === current);
	const previousIndex = (((currentIndex - 1) % length) + length) % length;
	const previous = playlist[previousIndex];
	dispatch(setCurrentAction(previous));
};
