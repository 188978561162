let { AudioContext } = window;

if (window.webkitAudioContext) {
	global.isSafariHell = true;
}

if (!AudioContext) {
	const { webkitAudioContext } = window;
	const webkitDecodeAudioData = webkitAudioContext.prototype.decodeAudioData;

	webkitAudioContext.prototype.decodeAudioData = function decodeAudioData(audioBuffer) {
		return new Promise((resolve, reject) => {
			webkitDecodeAudioData.call(this, audioBuffer, resolve, reject);
		});
	};

	AudioContext = webkitAudioContext;
}

global.AudioContext = AudioContext;
