import React from 'react';
import Loadable from 'react-loadable';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';

import './App.scss';

// Screens
// import Home from './screens/Home';
// import Contributor from './screens/Contributor';
import Page404 from './screens/404';
import store from './store';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
	loader: () => import('./components/DefaultLayout/DefaultLayout'),
	loading,
});

const App = () => (
	<Provider store={store}>
		<HashRouter>
			<Switch>
				{/*
				<Route exact path="/login" name="Login Page" component={Login} />
				<Route exact path="/register" name="Register Page" component={Register} />
				*/}
				<Route exact path="/404" name="Page 404" component={Page404} />
				{/* <Route exact path="/500" name="Page 500" component={Page500} /> */}
				<Route path="/" name="Home" component={DefaultLayout} />
			</Switch>
		</HashRouter>
	</Provider>
);
export default App;
