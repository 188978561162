import {
	ADD_RESULTS,
} from '../actions/search';

export const STATE_KEY = 'search';

const getInitialState = () => ({
	ambiences: [],
	impulseResponses: [],
});

/**
* reducers
*/
const search = (state = getInitialState(), action) => {
	const { type, results } = action;

	if (type !== ADD_RESULTS) return state;

	return {
		...state,
		...results,
	};
};

export default search;
