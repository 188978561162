const { REACT_APP_API_URL } = process.env;

export const result = response => (
	new Promise((resolve, reject) => {
		if (!response.ok) {
			response.json()
				.then((error) => {
					if (!error.message) {
						error.message = error.error || 'Error';
					}
					reject(error);
				})
				.catch(reject);
			return;
		}
		resolve(response.json());
	})
);

export const requestNoContentType = (
	path,
	opts,
) => {
	const {
		base,
		token,
		...fetchOptions
	} = opts;

	const options = {
		...fetchOptions,
		headers: {
			...opts.headers,
		},
	};

	if (token) {
		options.headers.authorization = `basic ${token}`;
	}

	const api = `${(base || REACT_APP_API_URL)}${path}`;

	return fetch(
		api,
		options,
	).then(result);
};

export default (path, opts) => {
	const options = {
		...opts,
		headers: {
			...opts.headers,
		},
	};

	// Add Content-Type only if not multipart to allow fetch api to add correct boundary
	if (!(options.body instanceof FormData)) {
		options.headers['Content-Type'] = 'application/json';
	}

	return requestNoContentType(path, options);
};
