import { combineReducers } from 'redux';

import ambiencesEntitiesReducer, { STATE_KEY as AMBIENCES_STATE_KEY } from './ambiences';
import ambienceTypesEntitiesReducer, { STATE_KEY as AMBIENCE_TYPES_STATE_KEY } from './ambienceTypes';
import causalElementCategoriesEntitiesReducer, { STATE_KEY as CAUSAL_ELEMENT_CATEGORIES_STATE_KEY } from './causalElementCategories';
import collectionsEntitiesReducer, { STATE_KEY as COLLECTIONS_STATE_KEY } from './collections';
import impulseResponsesEntitiesReducer, { STATE_KEY as IMPULSE_RESPONSES_STATE_KEY } from './impulseResponses';
import mainMaterialCategoriesEntititesReducer, { STATE_KEY as MAIN_MATERIAL_CATEGORIES_STATE_KEY } from './mainMaterialCategories';
import microphoneBrandsEntitiesReducer, { STATE_KEY as MICROPHONE_BRANDS_STATE_KEY } from './microphoneBrands';
import narrativeElementCategoriesEntitiesReducer, { STATE_KEY as NARRATIVE_ELEMENT_CATEGORIES_STATE_KEY } from './narrativeElementCategories';
import programTypesEntitiesReducer, { STATE_KEY as PROGRAM_TYPES_STATE_KEY } from './programTypes';
import recorderBrandsEntitiesReducer, { STATE_KEY as RECORDER_BRANDS_STATE_KEY } from './recorderBrands';
import recordingTypesEntitiesReducer, { STATE_KEY as RECORDING_TYPES_STATE_KEY } from './recordingTypes';
import secondaryMaterialCategoriesEntititesReducer, { STATE_KEY as SECONDARY_MATERIAL_CATEGORIES_STATE_KEY } from './secondaryMaterialCategories';
import structureCategoriesEntitiesReducer, { STATE_KEY as STRUCTURE_CATEGORIES_STATE_KEY } from './structureCategories';
import usersEntitiesReducer, { STATE_KEY as USERS_STATE_KEY } from './users';

export const STATE_KEY = 'entities';

/**
 * reducers
 */
const reducer = combineReducers({
	[AMBIENCES_STATE_KEY]: ambiencesEntitiesReducer,
	[AMBIENCE_TYPES_STATE_KEY]: ambienceTypesEntitiesReducer,
	[CAUSAL_ELEMENT_CATEGORIES_STATE_KEY]: causalElementCategoriesEntitiesReducer,
	[COLLECTIONS_STATE_KEY]: collectionsEntitiesReducer,
	[IMPULSE_RESPONSES_STATE_KEY]: impulseResponsesEntitiesReducer,
	[MAIN_MATERIAL_CATEGORIES_STATE_KEY]: mainMaterialCategoriesEntititesReducer,
	[MICROPHONE_BRANDS_STATE_KEY]: microphoneBrandsEntitiesReducer,
	[NARRATIVE_ELEMENT_CATEGORIES_STATE_KEY]: narrativeElementCategoriesEntitiesReducer,
	[PROGRAM_TYPES_STATE_KEY]: programTypesEntitiesReducer,
	[RECORDER_BRANDS_STATE_KEY]: recorderBrandsEntitiesReducer,
	[RECORDING_TYPES_STATE_KEY]: recordingTypesEntitiesReducer,
	[SECONDARY_MATERIAL_CATEGORIES_STATE_KEY]: secondaryMaterialCategoriesEntititesReducer,
	[STRUCTURE_CATEGORIES_STATE_KEY]: structureCategoriesEntitiesReducer,
	[USERS_STATE_KEY]: usersEntitiesReducer,
});

export default reducer;
