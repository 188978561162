import { login } from '../../api/authentication';
import { update, clear, get } from '../../lib/authentication';

const ACTION_KEY = 'authentication';

/**
 * actions
 */
export const LOGIN_FAILURE = `${ACTION_KEY}/login/FAILURE`;
export const LOGIN_REQUEST = `${ACTION_KEY}/login/REQUEST`;
export const LOGIN_SUCCESS = `${ACTION_KEY}/login/SUCCESS`;
export const LOGOUT = `${ACTION_KEY}/logout`;
export const UPDATE = `${ACTION_KEY}/update`;

/**
 * selectors
 */
export function select(state) {
	return state.authentication;
}

export function selectUser(state) {
	return select(state).user;
}

export function selectToken(state) {
	return select(state).token;
}

/**
 * actions creators
 */
function requestAction() { return { type: LOGIN_REQUEST }; }
function successAction(auth) { return { type: LOGIN_SUCCESS, auth }; }
function failureAction(error) { return { type: LOGIN_FAILURE, error }; }

export function loginAction(username, password) {
	return ({ dispatch }) => {
		dispatch(requestAction({ username }));

		return login(username, password)
			.then(
				(auth) => {
					update(auth);
					dispatch(successAction(auth));
				},
				(error) => {
					dispatch(failureAction(error));
					throw error;
				},
			);
	};
}

export function logoutAction() {
	clear();
	return { type: LOGOUT };
}

export function updateAction(updatedUserProfile) {
	const prevLocalStorage = get();
	const { token, user } = prevLocalStorage;

	const updatedProfile = { ...user, ...updatedUserProfile };

	const nextLocalStorage = {
		token,
		user: Object.assign({}, updatedProfile),
	};

	update(nextLocalStorage);

	return { type: UPDATE };
}
