const ACTION_KEY = 'entities';

/**
 * actions
 */
export const ADD_ENTITIES = `${ACTION_KEY}/add-entities`;
export const REMOVE_ENTITIES = `${ACTION_KEY}/remove-entities`;
export const REMOVE_ENTITY = `${ACTION_KEY}/delete-entity`;

/**
 * selectors
 */
export const selectEntities = state => state.entities;

/**
 * actions creators
 */
export const addEntities = entities => ({
	type: ADD_ENTITIES,
	payload: entities,
});

export const removeEntity = (key, entity) => ({
	type: REMOVE_ENTITY,
	payload: {
		[key]: entity,
	},
});

export const removeEntities = entities => ({
	type: REMOVE_ENTITIES,
	payload: entities,
});
