import {
	createStore,
	combineReducers,
	applyMiddleware,
} from 'redux';
import { createLogger } from 'redux-logger';
import createThunkerMiddleware from 'redux-thunker';

import fetchApi from './lib/fetchApi';
import authenticationReducer, { STATE_KEY as AUTHENTICATION_STATE_KEY } from './reducers/authentication';
import errorReducer, { STATE_KEY as ERROR_STATE_KEY } from './reducers/error';
import entitiesReducer, { STATE_KEY as ENTITIES_STATE_KEY } from './reducers/entities';
import loadingReducer, { STATE_KEY as LOADING_STATE_KEY } from './reducers/loading';
import playerReducer, { STATE_KEY as PLAYER_STATE_KEY } from './reducers/player';
import searchReducer, { STATE_KEY as SEARCH_STATE_KEY } from './reducers/search';
import searchSeriesReducer, { STATE_KEY as SEARCH_SERIE_STATE_KEY } from './reducers/serie';

const logger = createLogger({
	predicate: () => true,
	collapsed: () => true,
});

const thunk = createThunkerMiddleware({
	extraArgumentsEnhanced: {
		fetchApi,
	},
});

const app = combineReducers({
	[AUTHENTICATION_STATE_KEY]: authenticationReducer,
	[ERROR_STATE_KEY]: errorReducer,
	[ENTITIES_STATE_KEY]: entitiesReducer,
	[LOADING_STATE_KEY]: loadingReducer,
	[PLAYER_STATE_KEY]: playerReducer,
	[SEARCH_STATE_KEY]: searchReducer,
	[SEARCH_SERIE_STATE_KEY]: searchSeriesReducer,
});

const store = createStore(
	app,
	applyMiddleware(thunk, logger),
);

export default store;
