import {
	ADD_RESULTS, CLEAR_RESULTS,
} from '../actions/serie';

export const STATE_KEY = 'series';

const getInitialState = () => ({
	impulseResponses: [],
	scene: [],
	space: [],
	label: null,
});

/**
* reducers
*/
const series = (state = getInitialState(), action) => {
	const { type, results } = action;

	switch (type) {
	case ADD_RESULTS:
		return {
			...state,
			...results,
		};
	case CLEAR_RESULTS:
		return getInitialState();
	default:
		return state;
	}
};

export default series;
