import { selectToken } from '../actions/authentication';
import request from '../../lib/request';

export const withApi = (apiCreator, actionCreator) => (
	(...actionCreatorArgs) => {
		const action = actionCreator(...actionCreatorArgs);

		return (reduxThunkerArgs, ...otherArgs) => action(
			{
				...reduxThunkerArgs,
				api: apiCreator(reduxThunkerArgs.fetchApi),
			},
			...otherArgs,
		);
	}
);

const fetchApi = ({ getState }) => (
	path,
	options,
) => {
	const token = selectToken(getState());

	const mergedOptions = {
		...options,
		token,
	};

	return request(path, mergedOptions);
};

export default fetchApi;
