import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line no-console
console.info(`ℹ️ v${process.env.REACT_APP_VERSION}`);

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
