import request from '../lib/request';

export const AUTHENTICATION_API_PATH = '/auth';

// eslint-disable-next-line import/prefer-default-export
export const login = (username, password) => {
	const basicToken = btoa(`${username}:${password}`);

	return request(
		`${AUTHENTICATION_API_PATH}/login`,
		{
			headers: {
				authorization: `basic ${basicToken}`,
			},
		},
	).then(user => (
		{
			token: basicToken,
			user,
		}
	));
};
