export const STATE_KEY = 'error';

const getInitialState = () => ({});

/**
* reducers
*/
const error = (state = getInitialState(), action) => {
	const { type } = action;
	const matches = /(.*)\/(REQUEST|FAILURE)$/.exec(type);

	// not a *_REQUEST / *_FAILURE actions, so we ignore them
	if (!matches) return state;

	const [, requestName, requestState] = matches;

	return {
		...state,
		// Store whether a request is happening at the moment or not
		// e.g. will be true when receiving GET_TODOS_REQUEST
		//      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
		[requestName]: requestState === 'FAILURE' ? action.error : '',
	};
};

export default error;
