export const STATE_KEY = 'loading';

const getInitialState = () => ({});

/**
 * reducers
 */
const loading = (state = getInitialState(), action) => {
	const { type } = action;
	const matches = /(.*)\/(REQUEST|SUCCESS|FAILURE)$/.exec(type);

	// not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
	if (!matches) return state;

	const [, requestName, requestState] = matches;

	const newValue = () => {
		const previousValue = state[requestName] || 0;
		if (requestState === 'REQUEST') {
			return previousValue + 1;
		}
		return Math.max(previousValue - 1, 0);
	};

	return {
		...state,
		// Store whether a request is happening at the moment or not
		// e.g. will be true when receiving GET_TODOS_REQUEST
		//      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
		[requestName]: newValue(),
	};
};

export default loading;
