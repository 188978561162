import { get as getAuthentication } from '../../lib/authentication';

import {
	LOGIN_FAILURE,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
} from '../actions/authentication';

export const STATE_KEY = 'authentication';

/**
 * reducers
 */
function getInitialState() {
	const storedAuthentication = getAuthentication();
	return storedAuthentication ? { loggedIn: true, ...storedAuthentication } : {};
}

export default function authenticationReducer(state = getInitialState(), action) {
	switch (action.type) {
	case LOGIN_REQUEST:
		return {
			loggingIn: true,
		};
	case LOGIN_SUCCESS:
		return {
			loggedIn: true,
			token: action.auth.token,
			user: action.auth.user,
		};
	case LOGIN_FAILURE:
		return {};
	case LOGOUT:
		return {};
	default:
		return state;
	}
}
