const ACTION_KEY = 'search';

/**
 * actions
 */
export const ADD_RESULTS = `${ACTION_KEY}/add-results`;

/**
 * selectors
 */
export const select = state => state.search;

/**
 * actions creators
 */
export const addResults = results => ({ type: ADD_RESULTS, results });
