import { ADD_ENTITIES, REMOVE_ENTITIES, REMOVE_ENTITY } from '../actions/entities';

const createEntityReducer = (entityName, idAttribute = '_id') => (state = {}, action) => {
	switch (action.type) {
	case ADD_ENTITIES:
		return Object.entries(action.payload[entityName] || {})
			.reduce((mergedEntities, [id, entity]) => (
				{
					...mergedEntities,
					[id]: {
						...(mergedEntities[id] || {}),
						...entity,
					},
				}
			), state);
	case REMOVE_ENTITIES: {
		return Object.keys(action.payload[entityName] || {})
			.reduce((newState, id) => {
				delete newState[id];
				return newState;
			}, { ...state });
	}
	case REMOVE_ENTITY: {
		if (!action[entityName]) return state;
		const newState = { ...state };
		const deletedEntities = Array.isArray(action[entityName])
			? action[entityName]
			: [action[entityName]];
		deletedEntities.forEach((deletedEntity) => { delete newState[deletedEntity[idAttribute]]; });
		return newState;
	}
	default:
		return state;
	}
};

export default createEntityReducer;
