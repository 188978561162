import {
	CLEAR_PLAYLIST,
	PAUSE,
	PLAY,
	SET_CURRENT,
	SET_PLAYLIST,
	TOGGLE,
} from '../actions/player';

export const STATE_KEY = 'player';

const getInitialState = () => ({
	current: null,
	isPlaying: false,
	playlist: [],
});

/**
* reducers
*/
const player = (state = getInitialState(), action) => {
	switch (action.type) {
	case CLEAR_PLAYLIST:
		return {
			...state,
			playlist: [],
		};
	case PAUSE:
		return {
			...state,
			isPlaying: false,
		};
	case PLAY:
		return {
			...state,
			isPlaying: true,
		};
	case SET_CURRENT:
		return {
			...state,
			current: (action.sound && action.sound._id) || null,
		};
	case SET_PLAYLIST:
		return {
			...state,
			playlist: [...action.playlist],
		};
	case TOGGLE:
		return {
			...state,
			isPlaying: !state.isPlaying,
		};
	default:
		return state;
	}
};

export default player;
