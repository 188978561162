const ACTION_KEY = 'series';

/**
 * actions
 */
export const ADD_RESULTS = `${ACTION_KEY}/add-results`;
export const CLEAR_RESULTS = `${ACTION_KEY}/clear-results`;

/**
 * selectors
 */
export const select = state => state.series;

/**
 * actions creators
 */
export const addResults = results => ({ type: ADD_RESULTS, results });
export const clearResults = () => ({ type: CLEAR_RESULTS });
