export const LOCAL_STORAGE_KEY = 'authentication';

export const update = auth => (
	localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(auth))
);

export const clear = () => (
	localStorage.removeItem(LOCAL_STORAGE_KEY)
);

export const get = () => (
	JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY))
);
